// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

 
//@import "font-awesome";
@import "bootstrap";
 
//variable overrides
 
@import "bootstrap-select";

@import "_bootstrap-datetimepicker";

//Items required to fit the logo to the navbar
.navbar-brand
{
    margin: 0;
    padding: 10;
}
.navbar-brand img
{
    max-height: 85%;
}

body {
	margin-bottom: 50px;
}
